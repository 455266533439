import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  blocker: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 100,
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center'
  },
  isOpen: {
    display: 'flex'
  },
  container: {
    position: 'relative',
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/oldPaper.jpg)`,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: '10px',
    margin: '20px',
    padding: '5px',
    boxShadow: '0px 0px 300px rgba(0,0,0,0.5)',
    maxHeight: '90vh',
    maxWidth: '90vw'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px'
  },
  spacer: {
    width: '40px'
  },
  title: {
    textAlign: 'center',
    fontFamily: theme.typography.h5.fontFamily,
    fontSize: theme.typography.h5.fontSize
  },
  close: {
    padding: '5px',
    alignSelf: 'flex-start'
  },
  content: {
    margin: '0px 20px',
    textAlign: 'center',
    overflow: 'auto'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px',
    marginTop: '0px',
    gap: '20px'
  }
}));
