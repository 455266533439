import React, {
  useCallback, useContext
} from 'react';
import { IClueInput } from 'src/interfaces/IPuzzles';
import { checkAnswer } from 'src/utils/string';
import { QuestContext } from 'src/providers/quest/QuestContext';
import { useStyles } from './styles';
import TextInput from '../TextInput';
import Hints from '../Hints';

function ClueInput(puzzleStep: IClueInput): React.ReactElement {
  const {
    data,
    onComplete,
    isComplete
  } = puzzleStep;
  const styles = useStyles();
  const [currentAnswer, setCurrentAnswer] = React.useState('');
  const { updateStep } = useContext(QuestContext);

  const onInputChange = useCallback((theirAnswer: string) => {
    setCurrentAnswer(theirAnswer);
    if (!isComplete && checkAnswer(theirAnswer, data.answer)) {
      onComplete();
    }
  }, [data.answer, isComplete, onComplete]);

  const onNewHint = useCallback(() => {
    const newState: IClueInput = {
      ...puzzleStep,
      data: {
        ...data,
        hints: data.hints?.map((hint, index) => (
          { ...hint, timeStamp: index === data.currentHintNumber ? Date.now() : hint.timeStamp }
        )),
        currentHintNumber: (data.currentHintNumber ?? 0) + 1
      }
    };
    updateStep(newState);
  }, [updateStep, puzzleStep, data]);

  return (
    <div className={styles.container}>
      {
        data.clue?.map((clue) => (
          <div key={clue} className={styles.clue}>{clue}</div>
        ))
      }
      {
        data.hints ? (
          <Hints
            hints={data.hints}
            currentHintNumber={data.currentHintNumber ?? 0}
            onNewHint={onNewHint}
            isPuzzleSolved={isComplete}
          />
        ) : null
      }
      {
        data.answer ? (
          <TextInput
            onChange={onInputChange}
            displayOnly={isComplete}
            value={isComplete ? data.answer : currentAnswer}
          />
        ) : null
      }
    </div>
  );
}

export default ClueInput;
