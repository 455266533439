import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  container: {
    marginBottom: '60px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px'
  },
  id: {
    textAlign: 'center',
    fontFamily: 'sans-serif'
  },
  map: {
    width: '100%'
  },
  lwl: {
    margin: '0px'
  },
  delete: {
    backgroundColor: '#cc3300',
    color: '#fff',
    backgroundImage: 'none'
  }
}));
