import React, {
  useCallback, useContext,
  useMemo
} from 'react';
import { ILWLExample } from 'src/interfaces/IPuzzles';
import { checkAnswer, getLWL } from 'src/utils/string';
import { QuestContext } from 'src/providers/quest/QuestContext';
import { useStyles } from './styles';
import TextInput from '../TextInput';
import Hints from '../Hints';
import LWLCode from '../LWLCode';

const lineWordLetterExample = `The quick brown
fox jumps over
the lazy dog`;

function LineWordLetterExample(puzzleStep: ILWLExample): React.ReactElement {
  const {
    data,
    onComplete,
    isComplete
  } = puzzleStep;
  const styles = useStyles();
  const [currentAnswer, setCurrentAnswer] = React.useState('');
  const { updateStep } = useContext(QuestContext);

  const onInputChange = useCallback((theirAnswer: string) => {
    setCurrentAnswer(theirAnswer);
    if (!isComplete && checkAnswer(theirAnswer, data.answer)) {
      onComplete();
    }
  }, [data.answer, isComplete, onComplete]);

  const onNewHint = useCallback(() => {
    const newState: ILWLExample = {
      ...puzzleStep,
      data: {
        ...data,
        hints: data.hints?.map((hint, index) => (
          { ...hint, timeStamp: index === data.currentHintNumber ? Date.now() : hint.timeStamp }
        )),
        currentHintNumber: (data.currentHintNumber ?? 0) + 1
      }
    };
    updateStep(newState);
  }, [updateStep, puzzleStep, data]);

  const lwlCode = useMemo(() => getLWL(lineWordLetterExample, data.answer), [data.answer]);

  return (
    <div className={styles.container}>
      <TextInput
        value={lineWordLetterExample}
        className={styles.examplePassageText}
        displayOnly
        noWrap
        textArea
      />
      <LWLCode code={lwlCode} />
      {
        data.hints ? (
          <Hints
            hints={data.hints}
            currentHintNumber={data.currentHintNumber ?? 0}
            onNewHint={onNewHint}
            isPuzzleSolved={isComplete}
          />
        ) : null
      }
      <TextInput
        onChange={onInputChange}
        displayOnly={isComplete}
        value={isComplete ? data.answer : currentAnswer}
      />
    </div>
  );
}

export default LineWordLetterExample;
