import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  loading: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: '#000',
    transition: `${theme.loading.removeAnimationTime}ms`,
    opacity: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '100px'
  },
  visible: {
    opacity: 1
  }
}));
