import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  fullSize: {
    width: '100%',
    paddingBottom: '100%',
    position: 'relative',
    overflow: 'hidden',
    margin: 'auto'
  },
  words: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase'
  },
  stretch: {
    transformOrigin: 'center',
    fontFamily: 'sans-serif',
    whiteSpace: 'nowrap',
    userSelect: 'none'
  },
  rotated: {
    position: 'absolute',
    transform: 'rotate(90deg)'
  }
}));
