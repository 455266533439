import React, { ComponentProps } from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';
import Button from '../Button';

interface IButtonWithKey extends ComponentProps<typeof Button> {
  key: string;
}
type IProps = {
  isOpen: boolean;
  onClose: () => void;
  buttons: IButtonWithKey[];
  title?: string;
  text?: string;
  content?: React.ReactElement;
};
function Modal({
  isOpen, onClose, buttons = [], title, text, content
}: IProps): React.ReactElement {
  const styles = useStyles();

  return (
    <div className={clsx(styles.blocker, { [styles.isOpen]: isOpen })} onClick={onClose} role="presentation">
      <div className={styles.container} onClick={(e) => e.stopPropagation()} role="presentation">
        <div className={styles.header}>
          <div className={styles.spacer} />
          {title && <div className={styles.title}>{title}</div>}
          <Button
            onClick={onClose}
            imgUrl={`${process.env.PUBLIC_URL}/icons/close.svg`}
            className={styles.close}
          />
        </div>
        <div className={styles.content}>
          {text}
          {content}
        </div>
        <div className={styles.buttons}>
          {buttons.map((button) => <Button {...button} />)}
        </div>
      </div>
    </div>
  );
}

Modal.defaultProps = {
  title: '',
  text: '',
  content: undefined
};

export default Modal;
