import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  input: {
    margin: '0px',
    textAlign: 'center'
  },
  output: {
    padding: '10px',
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/oldPaper.jpg)`,
    fontFamily: theme.typography.h5.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    borderRadius: '5px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    userSelect: 'none'
  },
  wheel: {
    padding: '20px 20px 0px 20px'
  }
}));
