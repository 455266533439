import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  clue: {
    textAlign: 'center',
    fontFamily: theme.typography.h5.fontFamily,
    fontSize: theme.typography.h5.fontSize
  }
}));
