import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  container: {
  },
  clue: {
    textAlign: 'center',
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize
  }
}));
