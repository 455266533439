import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { ICaeserCipher } from 'src/interfaces/IPuzzles';
import { alphabet, caeserOffset, checkAnswer } from 'src/utils/string';
import { QuestContext } from 'src/providers/quest/QuestContext';
import clsx from 'clsx';
import { useStyles } from './styles';
import LetterWheel from '../LetterWheel';
import TextInput from '../TextInput';
import CaeserLetter from '../CaeserLetter';
import Hints from '../Hints';

function Caeser(puzzleStep: ICaeserCipher): React.ReactElement {
  const {
    data,
    onComplete,
    isComplete
  } = puzzleStep;
  const styles = useStyles();
  const [message, setMessage] = useState('');
  const [offset, setOffset] = useState(0);
  const [currentAnswer, setCurrentAnswer] = React.useState('');
  const { updateStep } = useContext(QuestContext);

  useEffect(() => {
    if (!data.secretMessage) return;
    /*
      We don't want offset of 0 or 26 as that is the same as the secret
      so math.ceil to push above 0
      and (alphabet.length - 2) to keep below 26
     */
    const offsetAmount = Math.ceil(Math.random() * (alphabet.length - 2));
    const offsetSecretMessage = caeserOffset(data.secretMessage, -offsetAmount);
    setMessage(offsetSecretMessage);
  }, [data.secretMessage]);

  const onInputChange = useCallback((theirAnswer: string) => {
    setCurrentAnswer(theirAnswer);
    if (!isComplete && checkAnswer(theirAnswer, data.answer)) {
      onComplete();
    }
  }, [data.answer, isComplete, onComplete]);

  const renderOutput = useCallback(() => {
    const outputText = caeserOffset(message, -offset);
    const output: { letter: string; delay: number; key: string; }[] = [];
    for (let i = 0; i < outputText.length; i += 1) {
      output.push({
        key: message.substring(0, i + 1),
        letter: outputText[i],
        delay: i * 50
      });
    }

    return (
      <div className={clsx('noHighlight', styles.output)}>
        {
          output.map((item) => (
            <CaeserLetter
              key={item.key}
              letter={item.letter}
              animationTime={400}
              delay={item.delay}
            />
          ))
        }
      </div>
    );
  }, [message, offset, styles.output]);

  const onNewHint = useCallback(() => {
    const newState: ICaeserCipher = {
      ...puzzleStep,
      data: {
        ...data,
        hints: data.hints?.map((hint, index) => (
          { ...hint, timeStamp: index === data.currentHintNumber ? Date.now() : hint.timeStamp }
        )),
        currentHintNumber: (data.currentHintNumber ?? 0) + 1
      }
    };
    updateStep(newState);
  }, [updateStep, puzzleStep, data]);

  return (
    <div>
      {
      !data.secretMessage && (
        <TextInput
          textArea
          onChange={(val) => setMessage(val)}
          className={styles.input}
          value={message}
        />
      )
      }
      {renderOutput()}
      <div className={styles.wheel}>
        <LetterWheel
          letters={alphabet}
          outerLetters={alphabet}
          offsetChange={({ number }) => setOffset(number)}
        />
      </div>
      {
        data.hints ? (
          <Hints
            hints={data.hints}
            currentHintNumber={data.currentHintNumber ?? 0}
            onNewHint={onNewHint}
            isPuzzleSolved={isComplete}
          />
        ) : null
      }
      <TextInput
        onChange={onInputChange}
        displayOnly={isComplete}
        value={isComplete ? data.answer : currentAnswer}
      />
    </div>
  );
}

Caeser.defaultProps = {
  secretMessage: ''
};

export default Caeser;
