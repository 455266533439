import React, { useContext } from 'react';
import { UserContext } from 'src/providers/user/UserProvider';
import { Redirect } from 'react-router-dom';
import { DEFAULT_ROUTE_PATH, ROUTE } from 'src/routes';
import Button from '../Button';
import { useStyles } from './styles';

function SignIn(): React.ReactElement {
  const styles = useStyles();
  const {
    user,
    signInWithGoogle,
    isSigningIn,
    isAdmin
  } = useContext(UserContext);

  if (user) {
    if (isAdmin) {
      return <Redirect to={ROUTE.ADMIN_PORTAL.path} />;
    }
    return <Redirect to={DEFAULT_ROUTE_PATH} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>encountr.me</div>
        <img
          alt="encountr.me"
          src={`${process.env.PUBLIC_URL}/images/pirateHatBig.png`}
          className={styles.logo}
        />
        <div className={styles.blurb}>
          An engaging and unexpected way to explore your area
        </div>
        <Button
          onClick={signInWithGoogle}
          className={styles.googleButton}
          disabled={isSigningIn}
        />
      </div>
    </div>
  );
}

export default SignIn;
