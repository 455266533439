import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  cell: {
    background: 'none',
    border: 'none',
    fontWeight: 'bold',
    fontSize: '1.5em',
    textTransform: 'uppercase',
    transition: `${theme.transitionTime}ms`,
    padding: '0px',
    color: '#000',
    flex: '1',
    overflow: 'hidden',
    userSelect: 'none',
    margin: 0,
    boxSizing: 'border-box',
    maxWidth: '15%', // 100% / 7 letters (could be more but min is 7 letters)
    [theme.breakpoints.up('sm')]: {
      fontSize: '2em'
    }
  },
  hidden: {
    opacity: '0'
  },
  removeCell: {
    maxWidth: '0%'
  }
}));
