import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  content: {
    display: 'flex',
    gap: '50px',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '300px'
  },
  title: {
    textAlign: 'center',
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: 'bold'
  },
  logo: {
    width: '60%'
  },
  blurb: {
    textAlign: 'center',
    fontFamily: theme.typography.h6.fontFamily,
    fontSize: theme.typography.h6.fontSize
  },
  googleButton: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/btn_google_signin.png)`,
    backgroundSize: 'contain',
    width: '70%',
    // by using a container and setting the padding bottom on the button then we can
    // preserve the aspect ratio of the button image
    paddingBottom: '14%',
    backgroundColor: '#4285f4',
    borderRadius: '5px'
  }
}));
