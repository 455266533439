import { ILocation } from './ILocation';
import { IWordSearchData } from './IWordSearch';

export enum PUZZLE_TYPE {
  WORDSEARCH = 'WORD_SEARCH',
  SAFE = 'SAFE',
  LOCATION_INPUT = 'LOCATION_INPUT',
  CAESER_CIPHER = 'CAESER_CIPHER',
  STRETCHED_TEXT = 'STRETCHED_TEXT',
  CLUE_INPUT = 'CLUE_INPUT',
  LWL_EXAMPLE = 'LWL_EXAMPLE'
}

export type ILocationImage = {
  locationDocId: string;
  imageIdx: number;
};

export type ILocationImageWithSrc = ILocationImage & {
  src: string;
};

export type IHint = {
  text: string;
  timeStamp?: number;
  image?: ILocationImage;
};

type IPuzzleStepGeneric<T> = {
  onComplete: () => void;
  id: string;
  isComplete: boolean;
  type: PUZZLE_TYPE;
  data: T & {
    hints?: IHint[];
    currentHintNumber?: number;
  };
};

export type IWordSearch = IPuzzleStepGeneric<IWordSearchData> & {
  type: PUZZLE_TYPE.WORDSEARCH;
};

export type ISafe = IPuzzleStepGeneric<{
  code: string;
}> & {
  type: PUZZLE_TYPE.SAFE;
};

export type ILocationInput = IPuzzleStepGeneric<ILocation> & {
  type: PUZZLE_TYPE.LOCATION_INPUT;
};

export type ICaeserCipher = IPuzzleStepGeneric<{
  secretMessage?: string;
  answer: string;
}> & {
  type: PUZZLE_TYPE.CAESER_CIPHER;
};

export type IStretchedText = IPuzzleStepGeneric<{
  secretMessage: string;
  decoy: string;
  answer: string;
}> & {
  type: PUZZLE_TYPE.STRETCHED_TEXT;
};

export type ILWLExample = IPuzzleStepGeneric<{
  answer: string;
}> & {
  type: PUZZLE_TYPE.LWL_EXAMPLE;
};

export type IClueInput = IPuzzleStepGeneric<{
  answer: string;
  clue?: string[];
}> & {
  type: PUZZLE_TYPE.CLUE_INPUT;
};

export type IPuzzleStep =
  IWordSearch
  | ISafe
  | ILocationInput
  | ICaeserCipher
  | IStretchedText
  | IClueInput
  | ILWLExample;

export type IQuest = {
  id: string;
  name: string;
  steps: IPuzzleStep[];
};
