import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import clsx from 'clsx';
import { alphabet } from 'src/utils/string';
import { useStyles } from './styles';

type IProps = {
  letter: string;
  animationTime: number;
  delay: number;
};
function CaeserLetter({ letter, animationTime, delay }: IProps): React.ReactElement {
  const styles = useStyles();
  const [displayLetter, setDisplayLetter] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const animationTimeout = useRef<NodeJS.Timeout>();
  const delayTimeout = useRef<NodeJS.Timeout>();

  const animateLetter = useCallback(() => {
    setIsAnimating(true);
    animationTimeout.current = setTimeout(() => {
      setDisplayLetter(letter);
      setIsAnimating(false);
    }, animationTime);
  }, [letter, animationTime]);

  useEffect(() => {
    if (!alphabet.includes(letter.toLocaleUpperCase())) {
      setDisplayLetter(letter);
    } else {
      delayTimeout.current = setTimeout(animateLetter, delay);
    }
  }, [letter, delay, animateLetter]);

  useEffect(() => {
    const clearTimeouts = (): void => {
      clearTimeout(delayTimeout.current);
      clearTimeout(animationTimeout.current);
    };

    return () => clearTimeouts();
  }, []);

  return (
    <div
      className={
        clsx(
          styles.letter,
          {
            [styles.space]: displayLetter === ' ',
            [styles.animating]: isAnimating
          }
        )
      }
      style={{ transition: `transform ${animationTime}ms` }}
    >
      {displayLetter}
    </div>
  );
}

export default CaeserLetter;
