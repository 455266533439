import React, { useCallback, useContext } from 'react';
import { useTheme } from '@material-ui/core';
import { Redirect, useParams } from 'react-router-dom';
import { IPuzzleStep, PUZZLE_TYPE } from 'src/interfaces/IPuzzles';
import Wordsearch from 'src/components/Wordsearch';
import Caeser from 'src/components/Caeser';
import Safe from 'src/components/Safe';
import LocationInput from 'src/components/LocationInput';
import StretchedText from 'src/components/StretchedText';
import ClueInput from 'src/components/ClueInput';
import { scrollDown } from 'src/utils/dom';
import { QuestContext } from 'src/providers/quest/QuestContext';
import { DEFAULT_ROUTE_PATH } from 'src/routes';
import { getQuestCompletionString } from 'src/utils/string';
import LineWordLetterExample from 'src/components/LineWordLetterExample';
import { useStyles } from './styles';

function Quest(): React.ReactElement {
  const styles = useStyles();
  const theme = useTheme();
  const { quests, completeStep } = useContext(QuestContext);
  const params = useParams<{ id: string }>();
  const quest = quests.find((q) => q.id === params.id);
  const [isComplete, setIsComplete] = React.useState(false);

  const onStepCompleted = useCallback((stepId: string) => {
    const matchingStep = quest?.steps.find((step) => step.id === stepId);
    if (!matchingStep || !quest?.id) return;
    completeStep(stepId);
    scrollDown(theme.transitionTime);
  }, [quest, theme.transitionTime, completeStep]);

  const renderStep = useCallback((step: IPuzzleStep) => {
    const stepProps = {
      ...step,
      onComplete: () => onStepCompleted(step.id)
    };

    switch (stepProps.type) {
      case PUZZLE_TYPE.WORDSEARCH:
        return <Wordsearch key={stepProps.id} {...stepProps} />;
      case PUZZLE_TYPE.SAFE:
        return <Safe key={stepProps.id} {...stepProps} />;
      case PUZZLE_TYPE.LOCATION_INPUT:
        return <LocationInput key={stepProps.id} {...stepProps} />;
      case PUZZLE_TYPE.CAESER_CIPHER:
        return <Caeser key={stepProps.id} {...stepProps} />;
      case PUZZLE_TYPE.STRETCHED_TEXT:
        return <StretchedText key={stepProps.id} {...stepProps} />;
      case PUZZLE_TYPE.CLUE_INPUT:
        return <ClueInput key={stepProps.id} {...stepProps} />;
      case PUZZLE_TYPE.LWL_EXAMPLE:
        return <LineWordLetterExample key={stepProps.id} {...stepProps} />;
      default:
        return <></>;
    }
  }, [onStepCompleted]);

  const renderSteps = useCallback(() => {
    if (!quest) return <></>;
    const firstUncompleted = quest.steps.findIndex((step) => !step.isComplete);
    if (firstUncompleted === -1 && !isComplete) {
      setIsComplete(true);
    }
    const visibleSteps = firstUncompleted === -1 ? quest.steps : quest.steps.slice(0, firstUncompleted + 1);
    return visibleSteps.map(renderStep);
  }, [quest, renderStep, isComplete]);

  if (!quest) {
    return <Redirect to={DEFAULT_ROUTE_PATH} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>{quest.name}</div>
      {renderSteps()}
      {isComplete && <div className={styles.complete}>{getQuestCompletionString(quest.name)}</div>}
    </div>
  );
}

export default Quest;
