import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { ILocationDownload } from 'src/interfaces/ILocation';
import { ILocationImage, ILocationImageWithSrc } from 'src/interfaces/IPuzzles';
import { QuestContext } from 'src/providers/quest/QuestContext';

export function useFetchLocationImages<T extends Record<string, any>>(
  objects?: (T & { image?: ILocationImage })[]
): (T & { image?: ILocationImageWithSrc })[] {
  const [srcImages, setSrcImages] = useState<(T & { image?: ILocationImageWithSrc })[]>([]);
  const locations = useRef<ILocationDownload[]>([]);
  const { getLocation } = useContext(QuestContext);

  const fetchLocation = useCallback(async (docId: string) => {
    const location = locations.current.find((loc) => loc.id === docId);
    if (location) return location;
    const locationFB = await getLocation(docId);
    if (locationFB) {
      locations.current = [...locations.current, locationFB];
    }
    return locationFB;
  }, [getLocation]);

  useEffect((): void => {
    if (!objects) {
      setSrcImages([]);
      return;
    }
    const docIds = [...new Set(objects.map((obj) => obj.image?.locationDocId))].filter(Boolean) as string[];

    Promise.all(Array.from(docIds).map(fetchLocation)).then((locationsFB) => {
      const locationImages = objects.map((obj) => {
        const location = locationsFB.find((loc) => loc?.id === obj.image?.locationDocId);

        let src = '';
        if (location?.images && obj.image?.imageIdx !== undefined) {
          src = location.images[obj.image.imageIdx];
        }
        return {
          ...obj,
          image: {
            ...obj.image,
            src
          }
        } as (T & { image?: ILocationImageWithSrc });
      });
      setSrcImages(locationImages);
    });
  }, [getLocation, objects, locations, fetchLocation]);

  return srcImages;
}
