import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    maxWidth: theme.maxWidth,
    padding: theme.pagePadding
  },
  imgInput: {
    display: 'none'
  },
  lwlInput: {
    fontSize: `${theme.typography.body2.fontSize} !important`,
    fontFamily: `${theme.typography.body2.fontFamily} !important`
  }
}));
