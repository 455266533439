import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  safe: {
    maxWidth: theme.maxWidth
  },
  pinContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px'
  },
  pinInput: {
    margin: '0px 10px',
    fontSize: '2em',
    width: '1em',
    textAlign: 'center',
    color: '#333'
  },
  isCorrect: {
    color: '#000',
    textShadow: '0px 0px 2px #222'
  },
  completeSafe: {
    margin: '10px auto'
  }
}));
