import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  container: {
    minHeight: '100%',
    maxWidth: theme.maxWidth,
    padding: theme.pagePadding,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  personal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px 0px'
  },
  button: {
    marginTop: '10px'
  },
  addQuestBtn: {
    margin: 'auto 0px'
  }
}));
