import React, { useMemo } from 'react';
import { useStyles } from './styles';

function LWLCode({ code }: {code: string}): React.ReactElement {
  const styles = useStyles();

  const letterCodes = useMemo(() => (
    code.match(/\[.*?\]|[^[\]]/g) ?? []).filter(
    (chunk) => !!chunk.trim()
  ), [code]);

  return (
    <div className={styles.lwlCode}>
      {
        letterCodes.map((letterCode, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${letterCode}_${index}`}>{letterCode}</div>
        ))
      }
    </div>
  );
}

export default LWLCode;
