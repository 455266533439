import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  draggableList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    margin: '20px 0px'
  },
  draggableItem: {
    display: 'flex',
    alignItems: 'center'
  },
  knob: {
    fontSize: '25px',
    padding: '10px',
    color: '#F5DEB3',
    textShadow: '0px 0px 2px #000'
  },
  dragging: {
    cursor: 'grabbing'
  }
}));
