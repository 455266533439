import React, { useCallback, useContext, useState } from 'react';
import { UserContext } from 'src/providers/user/UserProvider';
import { useHistory } from 'react-router-dom';
import { ROUTE } from 'src/routes';
import { QuestContext } from 'src/providers/quest/QuestContext';
import clsx from 'clsx';
import Button from '../Button';
import { useStyles } from './styles';

function AdminPortal(): React.ReactElement {
  const styles = useStyles();
  const { user } = useContext(UserContext);
  const { quests, addNewQuest } = useContext(QuestContext);
  const history = useHistory();
  const [disableAddQuest, setDisableAddQuest] = useState(false);

  const addQuest = useCallback(async () => {
    setDisableAddQuest(true);
    const questId = await addNewQuest();
    setDisableAddQuest(false);
    history.push(`${ROUTE.QUEST.path}/${questId}`);
  }, [addNewQuest, history]);

  return (
    <div className={styles.container}>
      <div className={styles.personal}>
        <img src={user?.photoURL} alt="user" />
        <p>{`Welcome ${user?.displayName}!`}</p>
      </div>

      <Button
        className={styles.button}
        onClick={() => history.push(ROUTE.UPLOAD_LOCATION.path)}
        text="Upload location"
      />
      <Button
        className={styles.button}
        onClick={() => history.push(ROUTE.VIEW_LOCATIONS.path)}
        text="Locations"
      />
      <Button
        className={styles.button}
        onClick={() => history.push(ROUTE.QUESTS.path)}
        text="Quests"
        disabled={quests.length === 0}
      />

      <Button
        className={
          clsx(
            styles.button,
            styles.addQuestBtn
          )
          }
        onClick={addQuest}
        disabled={disableAddQuest}
        text="add new quest"
      />
    </div>
  );
}

export default AdminPortal;
