import React,
{
  TouchEvent,
  MouseEvent,
  useRef,
  useState,
  useCallback,
  useEffect
} from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';

export type IOffsetChange = {
  number: number;
  anticlockwise: boolean;
};

interface IProps {
  letters: string;
  offsetChange: (change: IOffsetChange) => void;
  initialOffset?: number;
  outerLetters?: string;
  className?: string;
  dialShadow?: boolean;
  isDisabled?: boolean;
  circleMarkers?: boolean;
}

function LetterWheel({
  letters,
  offsetChange,
  initialOffset,
  outerLetters,
  className,
  dialShadow,
  isDisabled,
  circleMarkers
}: IProps): React.ReactElement {
  const styles = useStyles();
  const [mouseDownAngle, setMouseDownAngle] = useState(0);
  const [previousCircleRotation, setPreviousCircleRotation] = useState(0);
  const [currentCircleRotation, setCurrentCircleRotation] = useState(0);
  const [currentOffset, setCurrentOffset] = useState(initialOffset || 0);
  const [isDragging, setIsDragging] = useState(false);
  const circleRef = useRef<HTMLDivElement>(null);

  const alphabet = letters.toLocaleUpperCase().split('');
  const letterSliceDeg = 360 / alphabet.length;
  const outerAlphabet = outerLetters?.toLocaleUpperCase().split('');
  const outletterSliceDeg = 360 / (outerAlphabet?.length || 1);

  const rotationDegreeToLetterOffset = useCallback(() => {
    // I want the offset change points to be halfway between the letters
    // so the center of the selected value is centered on the letter (but this means I could go negative, so avoid that)
    let newOffset = Math.floor((currentCircleRotation - (letterSliceDeg / 2)) / letterSliceDeg);
    if (newOffset < 0) newOffset = alphabet.length - 1;
    return alphabet.length - 1 - newOffset;
  }, [alphabet.length, currentCircleRotation, letterSliceDeg]);

  function getMouseAngle(e: MouseEvent|TouchEvent): number|null {
    if (!circleRef.current) return null;
    // get the center of the circle
    const rect = circleRef.current.getBoundingClientRect();
    const cx = rect.left + rect.width / 2;
    const cy = rect.top + rect.height / 2;

    // get the angle between the mouse position and the center of the circle
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;

    const dx = clientX - cx;
    const dy = clientY - cy;
    const angle = (Math.atan2(dy, dx) * (180 / Math.PI)) + 180;

    return angle;
  }

  const handleMouseDown = useCallback((e: MouseEvent | TouchEvent): void => {
    const angle = getMouseAngle(e);
    if (angle && !isDisabled) {
      setMouseDownAngle(angle);
      setPreviousCircleRotation(currentCircleRotation);
      setIsDragging(true);
    }
  }, [currentCircleRotation, isDisabled]);

  const handleMouseUp = useCallback((): void => {
    setIsDragging(false);
  }, []);

  const handleDragEvent = useCallback((e: MouseEvent | TouchEvent): void => {
    if (!isDragging || isDisabled) return;
    const angle = getMouseAngle(e);
    if (angle) {
      const mouseAmountMoved = angle - mouseDownAngle;

      const currentCircleRot = (previousCircleRotation + mouseAmountMoved + 360) % 360;
      setCurrentCircleRotation(currentCircleRot);

      const newOffset = rotationDegreeToLetterOffset();
      if (currentOffset !== newOffset) {
        let isAnticlockwise = currentOffset <= newOffset;
        if (newOffset === 0 && currentOffset === alphabet.length - 1) isAnticlockwise = true;
        if (newOffset === alphabet.length - 1 && currentOffset === 0) isAnticlockwise = false;
        setCurrentOffset(newOffset);
        offsetChange({
          number: newOffset,
          anticlockwise: isAnticlockwise
        });
      }
    }
  }, [
    isDragging,
    isDisabled,
    mouseDownAngle,
    previousCircleRotation,
    rotationDegreeToLetterOffset,
    offsetChange,
    currentOffset,
    alphabet.length
  ]);

  const handleMouseMove = useCallback((e: MouseEvent): void => {
    handleDragEvent(e);
  }, [handleDragEvent]);

  const handleTouchMove = useCallback((e: TouchEvent): void => {
    if (isDisabled) return;
    e.preventDefault(); // this prevents the screen being scrolled in the background
    handleDragEvent(e);
  }, [handleDragEvent, isDisabled]);

  useEffect(() => {
    const circleElement = circleRef.current;
    if (circleElement) {
      circleElement.addEventListener('touchmove', handleTouchMove as unknown as EventListener, { passive: false });
    }

    return () => {
      if (circleElement) {
        circleElement.removeEventListener('touchmove', handleTouchMove as unknown as EventListener);
      }
    };
  }, [handleTouchMove]);

  useEffect(() => {
    if (initialOffset === undefined) return;
    const amountToRotateForInputOffset = 360 - (initialOffset * letterSliceDeg);
    setCurrentCircleRotation(amountToRotateForInputOffset);
    setCurrentOffset(initialOffset);
  }, [letterSliceDeg, initialOffset]);

  const renderCircleLetters = useCallback(() => alphabet.map((letter, index) => (
    <React.Fragment key={letter}>
      <div
        style={{ transform: `rotate(${index * letterSliceDeg}deg)` }}
        className={clsx('noHighlight', styles.letter, { [styles.biggerFont]: alphabet.length < 11 })}
      >
        {letter}
      </div>
      {
        circleMarkers && (
          <div
            style={{ transform: `rotate(${(index * letterSliceDeg) + (letterSliceDeg / 2)}deg)` }}
            className={clsx(styles.letter, styles.marker)}
          >
            I
          </div>
        )
      }
    </React.Fragment>
  )), [
    alphabet,
    letterSliceDeg,
    styles.letter,
    styles.marker,
    styles.biggerFont,
    circleMarkers
  ]);

  return (
    <div className={clsx(styles.container, className)}>
        <div className={clsx(styles.circleOuter)}>
          {
            outerAlphabet?.map((letter, index) => (
              <div
                key={letter}
                style={{ transform: `rotate(${index * outletterSliceDeg}deg)` }}
                className={clsx(styles.letter, { [styles.biggerFont]: outerAlphabet.length < 11 })}
              >
                {letter}
              </div>
            ))
          }
          {circleMarkers && <div className={styles.mainMarker} />}
        </div>

        <div
          ref={circleRef}
          className={clsx(styles.circle, { [styles.circleOpen]: dialShadow })}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onMouseLeave={() => setIsDragging(false)}
          onTouchStart={handleMouseDown}
          onTouchEnd={handleMouseUp}
          role="presentation"
          style={{ transform: `rotate(${currentCircleRotation}deg)` }}
        >
          {renderCircleLetters()}
        </div>

    </div>
  );
}

LetterWheel.defaultProps = {
  initialOffset: 0,
  outerLetters: '',
  className: '',
  dialShadow: false,
  isDisabled: false,
  circleMarkers: false
};

export default LetterWheel;
