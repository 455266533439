
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { Geolocation } from '@capacitor/geolocation';
import { Capacitor } from '@capacitor/core';
import { LoadScript } from '@react-google-maps/api';
import { IPosition } from 'src/interfaces/ILocation';
import { LocationContext } from './LocationContext';
import { UserContext } from '../user/UserProvider';

interface IProps {
  children?: ReactNode;
}

const LIBRARIES:('geometry'|'drawing'|'places'|'visualization')[] = ['geometry'];
const LOADING_ELEMENT = <></>;
function LocationProvider({ children }: IProps): React.ReactElement {
  const [myLocation, setMyLocation] = useState<IPosition|null>(null);
  const [locationError, setLocationError] = useState(false);
  const [myMarker, setMyMarker] = useState<google.maps.Icon>();
  const [xMarker, setXMarker] = useState<google.maps.Icon>();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user) return () => {};
    let position = '';
    const watchLocation = async (): Promise<void> => {
      if (Capacitor.getPlatform() !== 'web') {
        const status = await Geolocation.requestPermissions();
        if (status.location !== 'granted') {
          setLocationError(true);
          return;
        }
      }
      Geolocation.watchPosition({
        enableHighAccuracy: true,
        timeout: 5000
      }, (currentPosition, err) => {
        if (err) {
          console.warn('Error getting position', err);
          setLocationError(true);
        }
        if (currentPosition) {
          setMyLocation({
            lat: currentPosition.coords.latitude,
            lng: currentPosition.coords.longitude
          });
          setLocationError(false);
        }
      }).then((watchId) => {
        position = watchId;
      });
    };
    watchLocation();
    return () => {
      Geolocation.clearWatch({ id: position });
    };
  }, [user]);

  const setMarkers = useCallback(() => {
    const anchor = new google.maps.Point(20, 20);
    const scaledSize = new google.maps.Size(40, 40);
    const labelOrigin = new google.maps.Point(20, 40);

    setMyMarker({
      url: `${process.env.PUBLIC_URL}/images/pirateHat.png`,
      anchor,
      scaledSize,
      labelOrigin
    });

    setXMarker({
      url: `${process.env.PUBLIC_URL}/images/x.png`,
      anchor,
      scaledSize,
      labelOrigin
    });
  }, []);

  return (
    <LocationContext.Provider
      value={
        {
          myLocation,
          locationError,
          myMarker,
          xMarker
        }
      }
    >
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API || ''}
        onLoad={setMarkers}
        libraries={LIBRARIES}
        loadingElement={LOADING_ELEMENT}
      >
        {children}
      </LoadScript>
    </LocationContext.Provider>
  );
}

LocationProvider.defaultProps = {
  children: null
};

export { LocationContext, LocationProvider };
