import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  container: {
    maxWidth: theme.maxWidth,
    padding: theme.pagePadding,
    margin: 'auto',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '50px'
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    textTransform: 'uppercase'
  },
  complete: {
    paddingBottom: '20px',
    textAlign: 'center',
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize
  }
}));
