import React, { useCallback, useEffect, useState } from 'react';
import { ILocationDownload } from 'src/interfaces/ILocation';
import { subscribeToLocationsFB } from 'src/firebase/geoquery';
import { useStyles } from './styles';
import ViewLocation from '../ViewLocation';
import Button from '../Button';

function ViewLocations(): React.ReactElement {
  const styles = useStyles();
  const [locations, setLocations] = useState<ILocationDownload[]>([]);
  const [currentlyVisable, setCurrentlyVisable] = useState('');

  useEffect(() => {
    let unsubscribe: () => void;
    subscribeToLocationsFB(setLocations).then((unsub) => {
      unsubscribe = unsub;
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const toggleCurrentlyVisible = useCallback((id: string) => {
    setCurrentlyVisable((prev) => (prev === id ? '' : id));
  }, []);

  return (
    <div className={styles.container}>
      {
        locations.map((location) => (
          <div key={location.id}>
            <Button
              className={styles.clueButton}
              onClick={() => toggleCurrentlyVisible(location.id)}
              text={location.clue}
            />
            {currentlyVisable === location.id && <ViewLocation {...location} />}
          </div>
        ))
      }
    </div>
  );
}

export default ViewLocations;
