import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  header: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    justifyContent: 'space-between',
    width: '100%',
    transform: 'translateY(-100%)',
    transition: 'transform 0.5s ease-in-out',
    display: 'flex',
    paddingTop: '5px'
  },
  headerBtn: {
    width: '40px'
  },
  showHeader: {
    transform: 'translateY(0%)'
  },
  toasts: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pointerEvents: 'none',
    padding: '5px 20px'
  },
  toast: {
    padding: '10px 30px',
    margin: '10px 0px',
    borderRadius: '5px',
    background: 'rgba(0, 0, 0, 0.9)',
    color: '#ddd',
    textAlign: 'center'
  },
  error: {
    background: 'rgba(204, 51, 0, 0.9)'
  }
}));
