
import React, {
  useState,
  ReactNode,
  useContext,
  useEffect,
  useCallback
} from 'react';
import { IPuzzleStep, IQuest } from 'src/interfaces/IPuzzles';
import { addNewQuestFB, subscribeToQuestsFB, updateQuestFB } from 'src/firebase/quests';
import { createQuest } from 'src/puzzles/questUtils';
import { ILocationDownload } from 'src/interfaces/ILocation';
import { getLocationFB } from 'src/firebase/geoquery';
import { QuestContext } from './QuestContext';
import { UserContext } from '../user/UserContext';
import { UtilsContext } from '../utils/UtilsContext';

interface IProps {
  children?: ReactNode;
}

function QuestProvider({ children }: IProps): React.ReactElement {
  const [quests, setQuests] = useState<IQuest[]>([]);
  const { user } = useContext(UserContext);
  const { showToast } = useContext(UtilsContext);

  const matchingQuest = useCallback((stepId: string) => (
    quests.find((q) => q.steps.find((s) => s.id === stepId))
  ), [quests]);

  const updateQuest = useCallback(async (quest: IQuest) => {
    if (!user) return;
    await updateQuestFB(user.uid, quest);
  }, [user]);

  const updateStep = useCallback(async (step: IPuzzleStep) => {
    const quest = matchingQuest(step.id);
    if (!quest) return Promise.reject(new Error('Can\'t update, No matching quest'));
    const updatedQuest = {
      ...quest,
      steps: quest.steps.map((s) => {
        if (s.id !== step.id) return s;
        return {
          ...s,
          ...step
        };
      })
    };
    return updateQuest(updatedQuest);
  }, [matchingQuest, updateQuest]);

  const completeStep = useCallback((stepId: string) => {
    const quest = matchingQuest(stepId);
    if (!quest) return Promise.reject(new Error('Can\'t complete, No matching quest'));
    let isAlreadyCompleted = false;
    const updatedQuest = {
      ...quest,
      steps: quest.steps.map((s) => {
        if (s.id !== stepId) return s;
        if (s.isComplete) {
          isAlreadyCompleted = true;
          return s;
        }
        return {
          ...s,
          isComplete: true
        };
      })
    };
    if (isAlreadyCompleted) return Promise.resolve();
    return updateQuest(updatedQuest);
  }, [matchingQuest, updateQuest]);

  const addNewQuest = useCallback(async (): Promise<string> => {
    if (!user) return '';
    try {
      const quest = createQuest();
      await addNewQuestFB(user.uid, quest);
      return quest.id;
    } catch (err) {
      showToast('Unable to make new Quest', true);
      return '';
    }
  }, [user, showToast]);

  useEffect(() => {
    let unsubscribe: () => void;
    if (!user?.uid) return () => {};
    subscribeToQuestsFB(user.uid, setQuests).then((unsub) => {
      unsubscribe = unsub;
    });
    return () => {
      unsubscribe();
    };
  }, [user?.uid]);

  const getLocation = useCallback(async (docId: string): Promise<ILocationDownload|null> => {
    if (!user) return null;
    try {
      const location = getLocationFB(docId);
      return location;
    } catch (err) {
      showToast('Unable to getLocation', true);
      return null;
    }
  }, [user, showToast]);

  return (
    <QuestContext.Provider
      value={
        {
          quests,
          updateStep,
          completeStep,
          addNewQuest,
          getLocation
        }
      }
    >
      {children}
    </QuestContext.Provider>
  );
}

QuestProvider.defaultProps = {
  children: null
};

export { QuestContext, QuestProvider };
