import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';

type IProps = {
  image: string;
  alt?: string;
  onDelete?: () => void;
  className?: string;
};

function Image({
  image,
  alt,
  onDelete,
  className
}: IProps): React.ReactElement {
  const styles = useStyles();

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.imgContainer}>
        <img src={image} alt={alt} className={styles.img} draggable={false} />
        {
          onDelete && (
            <button
              type="button"
              className={styles.delImage}
              onClick={onDelete}
            >
              <span role="img" aria-label="delete">&#x274c;</span>
            </button>
          )
        }
      </div>
    </div>
  );
}

Image.defaultProps = {
  alt: '',
  onDelete: undefined,
  className: ''
};

export default Image;
