
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { IAudio, IAudioBufferType, preloadAudioFiles } from 'src/interactions/audio';
import { useUserInteraction } from 'src/hooks/UserInteraction';
import Button from 'src/components/Button';
import { ROUTE } from 'src/routes';
import { UtilsContext } from './UtilsContext';
import { useStyles } from './styles';
import { UserContext } from '../user/UserContext';

type IToast = {
  id: string;
  message: string;
  isError?: boolean;
};

const TOAST_TIMEOUT = 5000;

interface IProps {
  children?: ReactNode;
}

function UtilsProvider({ children }: IProps): React.ReactElement {
  const styles = useStyles();
  const [toasts, setToasts] = useState<IToast[]>([]);
  const [firstPage, setFirstPage] = useState('');
  const audioBuffers = useRef<IAudioBufferType[]>([]);
  const audioContext = useRef<AudioContext>();
  const { user, signOut, isAdmin } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = (): void => {
      const currentScrollPos = window.scrollY;
      // Show header if we're near the top or scrolling up
      const shouldShow = currentScrollPos < 15 || currentScrollPos < prevScrollPos; // Show when scrolling up
      setIsMenuVisible(shouldShow);
      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    preloadAudioFiles()
      .then((preloaded) => { audioBuffers.current = preloaded; });
  }, []);

  const showToast = useCallback((message: string, isError?: boolean) => {
    if (toasts.some((t) => t.message === message)) return () => {};
    const id = Math.random().toString();
    const toast = {
      id,
      message,
      isError
    };
    setToasts((prev) => [...prev, toast]);
    const cancelToast = (): void => setToasts((prev) => prev.filter((t) => t.id !== id));
    setTimeout(cancelToast, TOAST_TIMEOUT);
    return cancelToast;
  }, [toasts]);

  const playSound = useCallback((soundName?: IAudio): void => {
    const selectedBuffer = audioBuffers.current.find((buffer) => buffer.name === (soundName ?? 'silence'))?.buffer;
    if (selectedBuffer) {
      if (!audioContext.current) {
        audioContext.current = new AudioContext();
      }
      const sourceNode = audioContext.current.createBufferSource();
      sourceNode.buffer = selectedBuffer;
      sourceNode.connect(audioContext.current.destination);
      sourceNode.start();
    }
  }, [audioBuffers]);

  useUserInteraction(playSound);

  const renderHeader = useCallback(() => {
    if (!user) return <></>;
    if (!firstPage && location.key) setFirstPage(location.key);
    return (
      <div className={clsx(styles.header, { [styles.showHeader]: isMenuVisible })}>
        {
          (location.key !== firstPage) ? (
            <Button
              onClick={history.goBack}
              imgUrl={`${process.env.PUBLIC_URL}/icons/back.svg`}
              admin
            />
          ) : <div className={styles.headerBtn} />
        }
        {
          (isAdmin) ? (
            <Button
              onClick={() => history.push(ROUTE.ADMIN_PORTAL.path)}
              imgUrl={`${process.env.PUBLIC_URL}/icons/admin.svg`}
              admin
            />
          ) : <div className={styles.headerBtn} />
        }
        <Button
          onClick={signOut}
          imgUrl={`${process.env.PUBLIC_URL}/icons/signOut.svg`}
          admin
        />
      </div>
    );
  }, [
    user,
    firstPage,
    signOut,
    isMenuVisible,
    isAdmin,
    history,
    location.key,
    styles.header,
    styles.headerBtn,
    styles.showHeader
  ]);

  return (
    <UtilsContext.Provider
      value={
        {
          showToast,
          playSound
        }
      }
    >
      {children}
      <div className={styles.toasts}>
        {
          toasts.map((t) => (
            <div
              key={t.id}
              className={clsx(styles.toast, { [styles.error]: t.isError })}
            >
              {t.message}
            </div>
          ))
        }
      </div>
      {renderHeader()}
    </UtilsContext.Provider>
  );
}

UtilsProvider.defaultProps = {
  children: null
};

export { UtilsContext, UtilsProvider };
