import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import clsx from 'clsx';
import { UtilsContext } from 'src/providers/utils/UtilsContext';
import { QuestContext } from 'src/providers/quest/QuestContext';
import { IAudio } from 'src/interactions/audio';
import { ISafe } from 'src/interfaces/IPuzzles';
import { IS_DEV } from 'src';
import { useStyles } from './styles';
import LetterWheel, { IOffsetChange } from '../LetterWheel';
import Hints from '../Hints';
import Button from '../Button';

const NUMBERS = '0123456789';

type IPinInput = {
  idxId: number;
  value: string;
  isCorrect: boolean;
};

function getPinInputs(secret: string, isComplete = false): IPinInput[] {
  return secret.split('').map((val, idx) => ({
    idxId: idx,
    value: isComplete ? val : '0',
    isCorrect: isComplete
  }));
}

function Safe(puzzleStep: ISafe): React.ReactElement {
  const { data, onComplete, isComplete } = puzzleStep;
  const { code, hints } = data;
  const styles = useStyles();
  const [currentAnswer, setCurrentAnswer] = useState(getPinInputs(code));
  const [currentPinIdx, setCurrentPinIdx] = useState(0);
  const [currentlyAntiClockwise, setCurrentlyAntiClockwise] = useState(false);
  const { playSound } = useContext(UtilsContext);
  const { updateStep } = useContext(QuestContext);

  const offsetChange = useCallback(({ number, anticlockwise }: IOffsetChange) => {
    let newPinIdx = currentPinIdx;

    if (currentlyAntiClockwise !== anticlockwise) {
      // user changed direction so we increment the number
      // state is async so we need to keep value in newPinIdx here to avoid race conditions
      setCurrentPinIdx((prev) => {
        newPinIdx = (prev + 1) % currentAnswer.length;
        return newPinIdx;
      });
      setCurrentlyAntiClockwise(anticlockwise);
    }

    const newCurrentAnswer = currentAnswer.map((pinInput) => {
      if (pinInput.idxId === newPinIdx) {
        return {
          idxId: pinInput.idxId,
          value: NUMBERS[number],
          isCorrect: number === Number(code[newPinIdx])
        };
      }
      return pinInput;
    });

    setCurrentAnswer(newCurrentAnswer);

    const currentNumberCorrect = number === Number(code[newPinIdx]);
    let soundName: IAudio = currentNumberCorrect ? 'lock' : 'click';

    if (!isComplete && newCurrentAnswer.map(({ value }) => value).join('') === code) {
      soundName = 'doorOpen';
      onComplete();
    }
    playSound(soundName);
  }, [
    isComplete,
    currentAnswer,
    currentPinIdx,
    currentlyAntiClockwise,
    code,
    playSound,
    onComplete
  ]);

  useEffect(() => {
    if (isComplete) {
      setCurrentAnswer(getPinInputs(code, true));
    }
  }, [isComplete, code]);

  const onNewHint = useCallback(() => {
    const newState: ISafe = {
      ...puzzleStep,
      data: {
        ...data,
        hints: data.hints?.map((hint, index) => (
          { ...hint, timeStamp: index === data.currentHintNumber ? Date.now() : hint.timeStamp }
        )),
        currentHintNumber: (data.currentHintNumber ?? 0) + 1
      }
    };
    updateStep(newState);
  }, [updateStep, puzzleStep, data]);

  function showDeveloperStuff(): ReactElement {
    const completeSafe = (): void => {
      setCurrentAnswer(getPinInputs(code, true));
      onComplete();
    };
    return (
      <Button
        onClick={() => completeSafe()}
        text="Complete Safe"
        className={styles.completeSafe}
      />
    );
  }

  return (
    <div className={styles.safe}>
      {IS_DEV && showDeveloperStuff()}
      <div className={styles.pinContainer}>
        {
          currentAnswer.map((pinInput) => (
            <div
              key={pinInput.idxId}
              className={clsx(styles.pinInput, { [styles.isCorrect]: pinInput.isCorrect })}
            >
              {pinInput.value}
            </div>
          ))
        }
      </div>

      <LetterWheel
        letters={NUMBERS}
        offsetChange={offsetChange}
        dialShadow={isComplete}
        isDisabled={isComplete}
        circleMarkers
      />
      {
        hints ? (
          <Hints
            hints={hints}
            currentHintNumber={data.currentHintNumber ?? 0}
            onNewHint={onNewHint}
            isPuzzleSolved={isComplete}
          />
        ) : null
      }
    </div>
  );
}

export default Safe;
