import React, {
  useCallback, useContext, useEffect, useMemo
} from 'react';
import {
  GoogleMap,
  Marker
} from '@react-google-maps/api';
import { IPosition } from 'src/interfaces/ILocation';
import { LocationContext } from 'src/providers/location/LocationContext';
import { metersToDistanceString } from 'src/utils/string';
import { useStyles } from './styles';

interface IProps {
  setSpot: (spot: IPosition) => void;
  spot?: IPosition;
}

function AdminMap({ setSpot, spot }: IProps): React.ReactElement {
  const styles = useStyles();
  const { myLocation, myMarker, xMarker } = useContext(LocationContext);

  useEffect(() => {
    if (!myLocation || spot) return;
    // We set the default spot to the user's current location
    // If we don't have their location or the spot is already set then do nothing
    setSpot(myLocation);
  }, [myLocation, setSpot, spot]);

  const setMarker = useCallback((e: google.maps.MapMouseEvent): void => {
    const center = e.latLng?.toJSON();
    if (!center) return;
    const newSpot: IPosition = {
      lat: center.lat,
      lng: center.lng
    };

    setSpot(newSpot);
  }, [setSpot]);

  const distance = useMemo(() => {
    if (!myLocation || !spot) return null;
    const meters = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(myLocation),
      new google.maps.LatLng(spot)
    );
    return Math.round(meters);
  }, [myLocation, spot]);

  const distanceString = useMemo(() => {
    if (distance === null) return '';
    return metersToDistanceString(distance);
  }, [distance]);

  return (
    <GoogleMap
      mapContainerClassName={styles.mapContainer}
      center={myLocation ?? undefined}
      zoom={13}
      onClick={setMarker}
    >
      {myLocation && <Marker position={myLocation} icon={myMarker} label={distanceString} onClick={setMarker} />}
      {
      spot && (
        <Marker
          position={spot}
          icon={xMarker}
          label={distance !== null && distance > 15 ? distanceString : undefined}
          onClick={setMarker}
        />
      )
      }
    </GoogleMap>
  );
}

AdminMap.defaultProps = {
  spot: undefined
};

export default React.memo(AdminMap);
