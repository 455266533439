import React, { ReactElement } from 'react';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';
import { useStyles } from './styles';

interface IDraggableListProps<T> {
  items: T[];
  renderItem: (item: T) => ReactElement;
  onReorder: (newItems: T[]) => void;
  keyExtractor: (item: T) => string;
}

function DraggableList<T>({
  items,
  renderItem,
  onReorder,
  keyExtractor
}: IDraggableListProps<T>): React.ReactElement {
  const styles = useStyles();

  const onSortEnd = (oldIndex: number, newIndex: number):void => {
    onReorder(arrayMoveImmutable(items, oldIndex, newIndex));
  };

  return (
    <SortableList
      onSortEnd={onSortEnd}
      className={styles.draggableList}
      draggedItemClassName={styles.dragging}
    >
      {
        items.map((item) => (
          <SortableItem key={keyExtractor(item)}>
            <div className={styles.draggableItem}>
              <SortableKnob>
                <div className={styles.knob}>☰</div>
              </SortableKnob>
              {renderItem(item)}
            </div>
          </SortableItem>
        ))
      }
    </SortableList>
  );
}

export default DraggableList;
