import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  container: {
    maxWidth: theme.maxWidth,
    padding: theme.pagePadding,
    paddingBottom: '40px',
    margin: 'auto'
  },
  clueButton: {
    margin: '10px 0px',
    width: '100%'
  }
}));
