import { GeoDocumentReference, GeoFirestoreTypes, initializeApp } from 'geofirestore';
import firebase from 'firebase/compat/app';
import { auth, firestore } from 'src/firebase';
import {
  IEvent,
  ILocationDownload,
  ILocationUpload,
  IPosition
} from 'src/interfaces/ILocation';

const GeoFirestore = initializeApp(firestore);

const pointsOfInterestCollection = GeoFirestore.collection('pointsOfInterest');
const locationCollection = GeoFirestore.collection('locations');

function isValidLat(lat: number): boolean {
  return lat >= -90 && lat <= 90;
}

function isValidLng(lng: number): boolean {
  return lng >= -180 && lng <= 180;
}

function isValidLocation({ lat, lng }: IPosition): boolean {
  return isValidLat(lat) && isValidLng(lng);
}

export async function addPointOfInterestFB(event: IEvent): Promise<void> {
  if (!isValidLocation(event)) {
    throw new Error('Invalid coordinates');
  }

  await pointsOfInterestCollection.add(
    {
      ...event,
      coordinates: new firebase.firestore.GeoPoint(event.lat, event.lng)
    }
  );
}

export async function queryWithinRangeFB(pos: IPosition, radiusMeters: number): Promise<IEvent[]> {
  if (!isValidLocation(pos)) {
    throw new Error('Invalid coordinates');
  }

  const radiusKm = radiusMeters / 1000;

  const query = pointsOfInterestCollection.near({
    center: new firebase.firestore.GeoPoint(pos.lat, pos.lng),
    radius: radiusKm
  });

  const result = await query.get();
  const { docs } = result;

  return docs.map((doc) => {
    const event = doc.data() as unknown as (GeoFirestoreTypes.GeoDocumentData & IEvent);
    return {
      id: event.id,
      name: event.name,
      description: event.description,
      lat: event.g.geopoint.latitude,
      lng: event.g.geopoint.longitude
    };
  });
}

export async function addNewLocationFB(location: ILocationUpload): Promise<GeoDocumentReference> {
  if (!isValidLocation(location)) {
    throw new Error('Invalid coordinates');
  }
  if (!auth.currentUser) {
    throw new Error('User not logged in');
  }

  const resp = await locationCollection.add(
    {
      ...location,
      coordinates: new firebase.firestore.GeoPoint(location.lat, location.lng),
      uploadedBy: auth.currentUser.uid
    }
  );
  return resp;
}

export async function subscribeToLocationsFB(callback: (locations: ILocationDownload[]) => void): Promise<() => void> {
  const unsubscribe = locationCollection.onSnapshot((snapshot) => {
    const { docs } = snapshot;
    const locations = docs.map((doc) => {
      const location = doc.data() as unknown as (GeoFirestoreTypes.GeoDocumentData & ILocationDownload);
      return {
        clue: location.clue,
        lineWordLetter: location.lineWordLetter,
        images: location.images,
        id: doc.id,
        lat: location.g.geopoint.latitude,
        lng: location.g.geopoint.longitude,
        uploadedBy: location.uploadedBy
      };
    });
    callback(locations);
  });
  return unsubscribe;
}

export async function deleteLocationFB(id: string): Promise<void> {
  await locationCollection.doc(id).delete();
}

export async function getLocationFB(id: string): Promise<ILocationDownload|null> {
  const doc = await locationCollection.doc(id).get();
  if (!doc.exists) return null;
  const location = doc.data() as unknown as (GeoFirestoreTypes.GeoDocumentData & ILocationDownload);
  return {
    clue: location.clue,
    lineWordLetter: location.lineWordLetter,
    images: location.images,
    id: doc.id,
    lat: location.g.geopoint.latitude,
    lng: location.g.geopoint.longitude,
    uploadedBy: location.uploadedBy
  };
}
