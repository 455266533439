import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  lwlCode: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'baseline',
    gap: '10px',
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: '18px',
    marginBottom: '40px'
  }
}));
