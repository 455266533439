import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  letter: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    width: '0.7em',
    height: '1em'
  },
  animating: {
    transform: 'rotateX(180deg)'
  },
  space: {
    width: '10px'
  }
}));
