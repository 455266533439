import { useCallback, useState } from 'react';
import Compressor from 'compressorjs';
import heic2any from 'heic2any';

interface ICompressionResult {
  success: boolean;
  error?: string;
  finalSize?: number;
  compressionAttempts?: number;
  finalCompression?: number;
  base64?: string;
}

interface ICompressionStats {
  attempt: number;
  originalSize: string;
  newSize: string;
  reduction: string;
  quality: string;
}

interface IImageCompressorHook {
  addImage: (imgFile: File | Blob) => Promise<void>;
  clearImages: () => void;
  removeImage: (imageToRemove: string) => void;
  overwriteImages: (newImages: Set<string>) => void;
  images: Set<string>;
  totalImages: number;
  compressionStats?: ICompressionStats;
}

const IMG_MAX_DIMS = 1024; // Maximum width/height in pixels
const IMG_MAX_SIZE = 500 * 1024; // Maximum file size in bytes (500kB);
const MIN_COMPRESSION = 0.2; // Minimum compression quality
const COMPRESSION_STEP = 0.2; // How much to reduce compression each attempt

const useImageCompressor = (
  onError: (message: string) => void,
  debugMode = false
): IImageCompressorHook => {
  const [lastCompressionStats, setLastCompressionStats] = useState<ICompressionStats>();
  const [images, setImages] = useState<Set<string>>(new Set());

  const logCompression = useCallback((
    attempt: number,
    originalSize: number,
    newSize: number,
    quality: number
  ): ICompressionStats => {
    const stats = {
      attempt,
      originalSize: `${(originalSize / 1024).toFixed(2)}KB`,
      newSize: `${(newSize / 1024).toFixed(2)}KB`,
      reduction: `${((1 - newSize / originalSize) * 100).toFixed(1)}%`,
      quality: quality.toFixed(2)
    };

    if (debugMode) {
      console.log(stats);
      setLastCompressionStats(stats);
    }

    return stats;
  }, [debugMode]);

  const compressImage = useCallback(
    async (
      imgFile: File | Blob,
      compression = 0.6,
      attempt = 1,
      maxAttempts = 3
    ): Promise<ICompressionResult> => {
      const initialSize = imgFile.size;

      if (attempt > maxAttempts) {
        return {
          success: false,
          error: 'Max compression attempts reached',
          compressionAttempts: attempt - 1
        };
      }

      if (!imgFile.type.startsWith('image/')) {
        return {
          success: false,
          error: 'Invalid file type. Only images are allowed.'
        };
      }

      try {
        let imageToProcess = imgFile;
        if (imgFile.type === 'image/heic' || imgFile.type === 'image/heif') {
          const convertedBlob = await heic2any({
            blob: imgFile,
            toType: 'image/jpeg'
          }) as Blob;
          imageToProcess = new File([convertedBlob], 'converted.jpg', { type: 'image/jpeg' });
        }

        const result = await new Promise<Blob>((resolve, reject) => {
          /* eslint-disable no-new */
          new Compressor(imageToProcess, {
            quality: compression,
            maxWidth: IMG_MAX_DIMS,
            maxHeight: IMG_MAX_DIMS,
            success: resolve,
            error: reject
          });
        });

        logCompression(attempt, initialSize, result.size, compression);

        // If the image is still getting smaller, try another compression
        if (result.size < initialSize && attempt < maxAttempts) {
          return compressImage(result, compression, attempt + 1);
        }

        // If image is still too big, try with lower quality
        if (result.size > IMG_MAX_SIZE && compression > MIN_COMPRESSION) {
          const newCompression = Math.max(compression - COMPRESSION_STEP, MIN_COMPRESSION);
          return compressImage(result, newCompression, attempt + 1);
        }

        // Check final size
        if (result.size > IMG_MAX_SIZE) {
          return {
            success: false,
            error: `Image is too big (${(result.size / 1024 / 1024).toFixed(2)}MB). Maximum size is ${
              IMG_MAX_SIZE / 1024 / 1024
            }MB`,
            finalSize: result.size,
            compressionAttempts: attempt,
            finalCompression: compression
          };
        }

        // Convert to base64
        const base64 = await new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(result);
        });

        return {
          success: true,
          finalSize: result.size,
          compressionAttempts: attempt,
          finalCompression: compression,
          base64
        };
      } catch (error) {
        return {
          success: false,
          error: error instanceof Error ? error.message : 'Unknown compression error',
          compressionAttempts: attempt
        };
      }
    },
    [logCompression]
  );

  const addImage = useCallback(
    async (imgFile: File | Blob) => {
      try {
        const result = await compressImage(imgFile);
        if (result.success && result.base64) {
          setImages((prev) => {
            const newSet = new Set(prev);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            newSet.add(result.base64!);
            return newSet;
          });
        } else {
          onError(result.error || 'Failed to compress image');
        }
      } catch (error) {
        onError(error instanceof Error ? error.message : 'Unknown error processing image');
      }
    },
    [compressImage, onError]
  );

  const clearImages = useCallback(() => {
    setImages(new Set());
    setLastCompressionStats(undefined);
  }, []);

  const removeImage = useCallback((imageToRemove: string) => {
    setImages((prev) => {
      const newSet = new Set(prev);
      newSet.delete(imageToRemove);
      return newSet;
    });
  }, []);

  const overwriteImages = useCallback((newImages: Set<string>) => {
    setImages(new Set(newImages));
  }, []);

  return {
    addImage,
    clearImages,
    removeImage,
    compressionStats: lastCompressionStats,
    images,
    totalImages: images.size,
    overwriteImages
  };
};

export default useImageCompressor;
