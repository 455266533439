import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  textInput: {
    margin: '20px 0',
    padding: '15px 10px 10px 10px',
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/oldPaper.jpg)`,
    border: 'none',
    borderRadius: '5px',
    outline: 'none',
    fontFamily: theme.typography.h5.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    width: '100%',
    maxWidth: '100%',
    opacity: 1,
    textAlign: 'center',
    color: '#000',
    // prevent iOS making text grey in disabled state
    '-webkit-text-fill-color': '#000',
    '&::placeholder': {
      color: '#222'
    }
  },
  textArea: {
    resize: 'none',
    fontFamily: theme.typography.h5.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    backgroundAttachment: 'local',
    textAlign: 'left',
    overflowY: 'hidden'
  },
  noWrap: {
    textWrap: 'nowrap'
  }
}));
