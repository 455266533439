import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme/theme';
import { UserProvider } from './providers/user/UserProvider';
import './fonts/fonts.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './views/App';
import { UtilsProvider } from './providers/utils/UtilsProvider';
import { LocationProvider } from './providers/location/LocationProvider';
import { QuestProvider } from './providers/quest/QuestProvider';

export const IS_DEV = false;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <UtilsProvider>
            <LocationProvider>
              <QuestProvider>
                <App />
              </QuestProvider>
            </LocationProvider>
          </UtilsProvider>
        </UserProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
