import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';
import Image from '../Image';

interface IProps {
  isLoading: boolean;
}

function Loading({ isLoading }: IProps): React.ReactElement {
  const styles = useStyles();
  const theme = useTheme();
  const [displayLoading, setDisplayLoading] = useState(true);

  // When no longer loading then remove element after a delay
  // This gives us time to animate it away
  useEffect(() => {
    if (isLoading) {
      setDisplayLoading(true);
    } else {
      setTimeout(() => { setDisplayLoading(false); }, theme.loading.removeAnimationTime);
    }
  }, [isLoading, theme.loading.removeAnimationTime]);

  if (!displayLoading) return <></>;
  return (
    <div className={
      clsx(
        styles.loading,
        { [styles.visible]: isLoading }
      )
    }
    >
      <Image image={`${process.env.PUBLIC_URL}/images/pirateHatBig.png`} alt="encountr.me" />
    </div>
  );
}

export default Loading;
