import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imgContainer: {
    position: 'relative',
    maxHeight: '100%',
    maxWidth: '100%',
    height: '300px',
    width: '300px'
  },
  img: {
    borderRadius: '20px',
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    position: 'absolute'
  },
  delImage: {
    position: 'absolute',
    top: '0',
    right: '0',
    border: 'none',
    outline: 'none',
    height: '30px',
    width: '30px',
    color: '#fff',
    borderRadius: '0 0 0 5px',
    background: 'rgba(0, 0, 0, 0.5)',
    fontFamily: 'sans-serif'
  }
}));
